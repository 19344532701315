/* General Styles */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: black;
  color: #e0e0e0;
  line-height: 1.6;
  overflow-x: hidden;
}

.app {
  text-align: center;
}

/* Hero Section */
.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #000000, #4d4d4d);
  color: white;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-nav {
  display: flex;
  gap: 1rem; /* Space between buttons */
}

.hero-nav button {
  background-color: white;
  color: #121212;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  opacity: 0; /* Hidden initially */
  animation: fadeIn 1s ease-in-out forwards;
}

.hero-nav button:nth-child(1) {
  animation-delay: 0.5s;
}

.hero-nav button:nth-child(2) {
  animation-delay: 0.6s;
}

.hero-nav button:nth-child(3) {
  animation-delay: 0.7s;
}

.hero-nav button:nth-child(4) {
  animation-delay: 0.8s;
}

.hero-nav button:hover {
  background-color: rgb(93, 93, 93);
  transform: scale(1.1);
  transition: transform 0.3s, background-color 0.3s;
}

/* Section Styles */
.section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  animation: slideIn 1s ease-in-out;
}

.section h2 {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 1.5rem;
}

.section ul {
  list-style: none;
  padding: 0;
}

.section ul li {
  margin: 0.5rem 0;
}

.section p, .section ul li {
  font-size: 1.2rem;
  color: white;
}

/* Back Button */
.back-button {
  background-color: white;
  color: #121212;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 2rem;
  transition: transform 0.3s, background-color 0.3s;
}

.back-button:hover {
  background-color: rgb(93, 93, 93);
  transform: scale(1.1);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero-nav {
    flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
    justify-content: center;
  }

  .hero-nav button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .section h2 {
    font-size: 2rem;
  }

  .section p, .section ul li {
    font-size: 1rem;
  }
}
